var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OperationNocturneOPN313"},[_c('link',{attrs:{"href":"https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css","rel":"stylesheet"}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"LandingContent"},[_vm._m(6),_c('div',{staticClass:"LandingInput"},[_c('vs-button',{attrs:{"active":_vm.active == 0,"block":"","color":"#000000","size":"xl","href":"https://github.com/MkhuseliNgetu/Operation-Nocturne-OPN313"},on:{"click":function($event){_vm.active = 0}}},[[_c('i',{staticClass:"bx bx-game",attrs:{"color":"#FFFFFFF"}}),_vm._v("   Remember the basics of CQC ")]],2)],1)]),_c('br')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OperationNocturneOPN313LandingOne"},[_c('img',{attrs:{"id":"OperationNocturneOPN313LandingImageOne","src":require("../assets/Images/OperationNocturneOPN313/HeadersPromoOne.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OperationNocturneOPN313LandingTwo"},[_c('img',{attrs:{"id":"OperationNocturneOPN313LandingImageTwo","src":require("../assets/Images/OperationNocturneOPN313/HeadersPromoTwo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OperationNocturneOPN313LandingThree"},[_c('img',{attrs:{"id":"OperationNocturneOPN313LandingImageThree","src":require("../assets/Images/OperationNocturneOPN313/HeadersPromoThree.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OperationNocturneOPN313LandingFour"},[_c('img',{attrs:{"id":"OperationNocturneOPN313LandingImageFour","src":require("../assets/Images/OperationNocturneOPN313/HeadersPromoFour.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OperationNocturneOPN313LandingFive"},[_c('img',{attrs:{"id":"OperationNocturneOPN313LandingImageFive","src":require("../assets/Images/OperationNocturneOPN313/HeadersPromoFive.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OperationNocturneOPN313LandingSix"},[_c('img',{attrs:{"id":"OperationNocturneOPN313LandingImageSix","src":require("../assets/Images/OperationNocturneOPN313/HeadersPromoSix.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"LandingTitle"},[_c('h1',[_vm._v("Operation")]),_c('h2',[_vm._v("Nocturne")]),_c('h3',[_vm._v("OPN313")])])
}]

export { render, staticRenderFns }